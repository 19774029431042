import request from '../utils/request'

const URL = {
    // 矿商仓库     有个字段availableTargetStockNum在增加库存的时候用
    GENE_STOCK: '/minerDistribution/geneStock',
    // 矿商总库存    issuedStockNum总分配数量没用到
    ALL_STOCK: '/minerDistribution/allStock',
    // 创建分销商
    CREATE_DISTRIBUTOR: '/minerDistribution/create',
    // 分销商名单
    RESELLER_list: '/minerDistribution/resellerList',
    // 修改数量
    MODIFY_NUM: '/minerDistribution/modifyNum',
    // 回收账户
    RECYCLE: '/minerDistribution/recycle',
    // 设定名字
    MODIFY_NAME: '/minerDistribution/modify',
    // 下级分销商列表,targetAddress查谁的下级
    NEXT_RESELLER: '/minerDistribution/nextReseller',
    // 所有子分销列表
    SECOND_SELLER_LIST: '/minerDistribution/secondSellerList',
    // 销售总览明细
    SALE_TOTAL_INFO: '/minerDistribution/saleTotalInfo',
    // 销售明细
    SALE_DETAIL_INFO: '/minerDistribution/saleDetailInfo',
    // 导出销售明细
    EXPORT_DETAIL_INFO: '/minerDistribution/exportDetailInfo',
}
export default {
    getGeneStock(address: string, pageNum?: number, pageSize?: number, targetEthAddress?: string) {
        return request.get(URL.GENE_STOCK, {
            params: {
                ethAddress: address,
                pageNum,
                pageSize,
                targetEthAddress
            }
        })
    },
    getAllStock(address: string) {
        return request.get(URL.ALL_STOCK, {
            params: {
                ethAddress: address
            }
        })
    },
    createDistributor(formData: any) {
        return request.post(URL.CREATE_DISTRIBUTOR, formData)
    },
    getResellerList(address: string, pageNum?: number, pageSize?: number) {
        return request.get(URL.RESELLER_list, {
            params: {
                ethAddress: address,
                pageNum,
                pageSize,
            }
        })
    },
    modifyNum(formData: any) {
        return request.post(URL.MODIFY_NUM, formData)
    },
    recycleAccount(address: string, targetAddress: string) {
        return request.get(URL.RECYCLE, {
            params: {
                ethAddress: address,
                targetAddress
            }
        })
    },
    modifyName(formData: any) {
        return request.post(URL.MODIFY_NAME, formData)
    },
    getNextReseller(address: string, targetAddress: string, pageNum?: number, pageSize?: number) {
        return request.get(URL.NEXT_RESELLER, {
            params: {
                ethAddress: address,
                targetAddress,
                pageNum,
                pageSize,
            }
        })
    },
    getAllSubReseller(address: string, pageNum?: number, pageSize?: number) {
        return request.get(URL.SECOND_SELLER_LIST, {
            params: {
                ethAddress: address,
                pageNum,
                pageSize,
            }
        })
    },
    getSaleTotalInfo(minerId: string, associate:boolean) {
        return request.get(URL.SALE_TOTAL_INFO, {
            params: {
                minerId,
                associate
            }
        })
    },
    getSaleDetailInfo(minerId: string, associate:boolean, pageNum?: number, pageSize?: number) {
        return request.get(URL.SALE_DETAIL_INFO, {
            params: {
                minerId,
                associate,
                pageNum,
                pageSize,
            }
        })
    },
    exportDetail(minerId: string, associate:boolean) {
        return request.get(URL.EXPORT_DETAIL_INFO, {
            params: {
                minerId,
                associate
            }
        })
    },
}