import './editUserinfoDialog.scss'
import React, {useEffect, useState} from "react";
import User from "src/api/user.ts";
import {useMessage} from "src/view/components/MessageContext.tsx";
interface editUserinfoDialogProps {
    userInfo: any,
    closeDialog: () => void,
    saveUser: (user: any) => void,
}
export const EditUserinfoDialog:React.FC<editUserinfoDialogProps> = ({userInfo, closeDialog, saveUser}) => {
    const [username, setUsername] = useState(userInfo.nickName as string)
    const regex = /^[a-zA-Z0-9_]{0,15}$/
    const {showMessage, closeMessage} = useMessage()
    const changeUserValue = (e: any) => {
        const value = e.target.value;
        // 如果输入的值符合正则表达式，更新状态
        if (regex.test(value) || value === '') {
            setUsername(value);  // 允许输入
        }
    }
    const save = (username:any) => {
        const regex1 = /^[a-zA-Z0-9_]{4,15}$/
        if (regex1.test(username)) {
            saveUser(username)
        } else {
            showMessage('Invalid Nick Name Format')
        }
    }
    return (
        <div className={'show-flex-box-r show-flex-center edit-userinfo-dialog-wrap'}>
            <div className={'show-flex-box-c edit-userinfo-dialog-content'}>
                <div className={'c-00E6FF f-28 mt-30 ml-40'}>Create/Edit Information</div>
                <div className={'cursor-pointer close-icon'} onClick={closeDialog}></div>
                <div className={'flex-1 mt-16 show-flex-box-c pl-50 pr-50 pt-20 content'}>
                    <div className={'show-flex-box-r align-center'}>
                        <div className={'c-0BF f-20 ff-Inter'}>Avatar</div>
                        <div className={'c-59D f-16 ff-Inter'}>（Randomly generated, cannot be modified.）</div>
                    </div>
                    <div className={'show-flex-box-r show-flex-center mt-24 avatar'}>
                        <img className={'avatar-img'} src={userInfo.userAvatar} alt=""/>
                    </div>
                    <div className={'c-0BF f-20 mt-24 ff-Inter'}>User Name</div>
                    <input className={'mt-24 user-input'} value={username}
                           onChange={(e) => changeUserValue(e)}/>
                    <div className={'c-59D f-16 mt-10 ff-Inter'}>4-15 characters, support English and numbers,
                        underline
                    </div>
                    <div className={'mt-36 show-flex-box-r show-flex-sa btn-list'}>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer cancel-btn'}
                            onClick={closeDialog}>Cancel
                        </div>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer confirm-btn'}
                            onClick={() => save(username)}>Save
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}