import './inputNumber.scss'
import React, { useEffect, useRef, useState } from "react";
interface InputNumberProps {
    index: number,
    maxValue: number,
    currentValue: number,
    modifyNum: (index:number, value: number) => void,
}
export const InputNumber:React.FC<InputNumberProps> = ({index, maxValue, currentValue, modifyNum}) => {
    // const [numValue, setNumValue] = useState(currentValue as number)
    const changeAddressValue = (value: any) => {
        let inputValue = value.target.value;

        inputValue = inputValue.replace(/[^0-9]/g, '');
        if (inputValue > maxValue) {
            inputValue = maxValue;
        }
        value.target.value = inputValue;
        modifyNum(index, Number(value.target.value))
    }
    const stepNum = (num:number) => {
        if (currentValue + num > maxValue || currentValue + num < 0) return
        modifyNum(index, currentValue + num)
    }
    return (
        <div className={'w100 h100 show-flex-box-r show-flex-sb pl-6 pr-6 input-number-wrap'}>
            <div className={'cursor-pointer minus-icon'} onClick={() => stepNum(-1)}></div>
            <input type="text" className={'text-center address-input'} value={currentValue} onChange={(e) => changeAddressValue(e)}/>
            <div className={'cursor-pointer add-icon'} onClick={() => stepNum(1)}></div>
        </div>
    )
}