import './linkDialog.scss'
import React, {useEffect, useState} from "react";
import {useGlobalState} from "src/hook/useGlobalState.ts";
import {MinerApi} from 'src/api'
import {NoData} from 'src/view/components/noData.tsx'
import {handlePermillage} from "src/utils/utils.ts";
import { networkList } from "src/hook/network.ts";
import { networkStatus } from "src/hook/networkStatus.ts";
import { useCopy } from "src/hook/CopyContext.tsx";
interface linkDialogProps {
    userInfo: any,
    closeDialog: () => void,
}
export const LinkDialog:React.FC<linkDialogProps> = ({userInfo, closeDialog}) => {
    const {address} = useGlobalState()
    const tableTitle = ['', 'Remaining Quantity', 'Quantity Sold', 'Actions']
    const [inventoryList, setInventoryList] = useState([] as InventoryList[])
    const { copy } = useCopy()
    // 获取矿机仓库
    const getInventoryList = async (address:string) => {
        try {
            const res = await MinerApi.getGeneStock(address)
            setInventoryList(res.data.list)
        } catch (error) { console.log(error) }
    }
    // networkList[networkStatus].userDistributionUrl
    const copyLink = (id:string) => {
        if (id) {
            // copy(networkList[networkStatus].userDistributionUrl + '?geneId=' + id + '&minerVOId=' + userInfo.minerId);
            copy(networkList[networkStatus].userDistributionUrl + userInfo.viewCode + '/' + id);
        }
    }
    useEffect(() => {
        if (address) {
            getInventoryList(address)
        }
    }, [address]);
    return (
        <div className={'show-flex-box-r show-flex-center link-dialog-wrap'}>
            <div className={'show-flex-box-c link-dialog-content'}>
                <div className={'c-00E6FF f-28 mt-38 ml-40'}>My Sales Link</div>
                <div className={'cursor-pointer close-icon'} onClick={closeDialog}></div>
                <div className={'flex-1 mt-6 show-flex-box-c pl-50 pr-50 pt-20 content'}>
                    <div className={'show-flex-box-r align-center title-list'}>
                        {
                            tableTitle.map((item, index) => (
                                <div key={index} className={'c-59D f-14 single-title'}>{item}</div>
                            ))
                        }
                    </div>
                    <div className={'table-content'}>
                        {
                            inventoryList.map((item:any, index:number) => (
                                <div className={'mb-18 show-flex-box-r align-center mt-4 single-row'}>
                                    <div className={'pl-50'}>
                                        <div className={'bitlife-wrap'}>
                                            <div className={'cover'}
                                                 style={{backgroundImage: `url('${item.roleImage}')`}}>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={'c-0BF f-18'}>{handlePermillage(item.availableStockNum)}</div>
                                    <div className={'c-0BF f-18'}>{handlePermillage(item.saleStockNum)}</div>
                                    <div className={'c-00E6FF f-18 cursor-pointer copy-label'} onClick={() => copyLink(item.geneBaseId)}>Copy Sales Link</div>
                                </div>
                            ))
                        }
                        { inventoryList.length === 0 && <NoData></NoData> }
                    </div>
                </div>
            </div>
        </div>
    )
}