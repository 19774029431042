import './modifyNum.scss'
import React, { useEffect, useRef, useState } from "react";
import {handlePermillage} from "src/utils/utils.ts";
import {InputNumber} from "./inputNumber.tsx";
import { getStr } from "src/utils/utils.ts";
interface modifyNumProps {
    title: string,
    btnLabel: string,
    inventoryList: Array<InventoryList>,
    targetAddress: string,
    closeDialog: () => void,
    clickConfirm: (title:string, bitlifeList:any) => void,
}
export const ModifyNum:React.FC<modifyNumProps> = ({title, btnLabel, inventoryList, targetAddress, closeDialog, clickConfirm}) => {
    const [numValue, setNumValue] = useState(100 as number)
    const [bitlifeList, setBitlifeList] = useState([] as any)
    useEffect(() => {
        if (inventoryList.length > 0) {
            const arr = inventoryList.map((item:any, index: number) => {
                return {
                    ...item,
                    inputNum: 0
                }
            })
            setBitlifeList(arr)
        }
    }, []);
    const modifyNum = (index:number, num:number) => {
        const newBitlifeList = bitlifeList.map((item:any, idx:number) => {
            if (idx === index) {
                return { ...item, inputNum: num };
            }
            return item;
        });
        setBitlifeList(newBitlifeList)
    }
    // const clickConfirm = () => {
    //     if (title.includes('Create')) {
    //         clickCreate(bitlifeList)
    //     }
    // }
    return (
        <div className={'show-flex-box-r show-flex-center modify-num-wrap'}>
            <div className={'show-flex-box-c modify-num-content'}>
                <div className={'c-00E6FF f-28 mt-36 ml-40'}>{title}</div>
                <div className={'cursor-pointer close-icon'} onClick={closeDialog}></div>
                <div className={'flex-1 mt-26 show-flex-box-c pl-40 pr-30 pt-20 content'}>
                    <div className={'pr-10 bitlife-list'}>
                        {
                            title.includes('Create') && bitlifeList.map((item: any, index: number) => (
                                <div key={index} className={'show-flex-box-r show-flex-sb single-row'}>
                                    <div className={'bitlife-wrap'}>
                                        <div className={'cover'}
                                             style={{backgroundImage: `url('${item.roleImage}')`}}>
                                        </div>
                                    </div>
                                    <div className={'show-flex-box-c'}>
                                        <div className={'c-59D f-20'}>Remaining:</div>
                                        <div className={'c-0BF f-24'}>{handlePermillage(item.availableStockNum)}</div>
                                    </div>
                                    <div className={'mr-30'}>
                                        <div className={'c-59D mb-10 f-20'}>Quantity</div>
                                        <div className={'input-number-wrap'}>
                                            <InputNumber index={index} maxValue={item.availableStockNum}
                                                         currentValue={item.inputNum} modifyNum={modifyNum}></InputNumber>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                        {
                            !title.includes('Create') && bitlifeList.map((item: any, index: number) => (
                                <div key={index} className={'show-flex-box-r show-flex-sb single-row'}>
                                    <div className={'bitlife-wrap'}>
                                        <div className={'cover'}
                                             style={{backgroundImage: `url('${item.roleImage}')`}}>
                                        </div>
                                    </div>
                                    <div className={'show-flex-box-c'}>
                                        <div className={'c-59D f-20 label'}>My BitLife</div>
                                        <div className={'c-59D f-20 label'}>Inventory</div>
                                        <div className={'c-0BF f-24'}>{handlePermillage(item.availableStockNum)}</div>
                                    </div>
                                    <div className={'show-flex-box-c'}>
                                        <div className={'c-59D f-20 label'}>{getStr(targetAddress, 4, 4)}</div>
                                        <div className={'c-59D f-20 label'}>Inventory</div>
                                        <div className={'c-0BF f-24'}>{handlePermillage(item.availableTargetStockNum)}</div>
                                    </div>
                                    <div className={'mr-30'}>
                                        <div className={'c-59D mb-10 f-20'}>Quantity</div>
                                        <InputNumber index={index} maxValue={ title.includes('Increase') ? item.availableStockNum : item.availableTargetStockNum }
                                                     currentValue={item.inputNum} modifyNum={modifyNum}></InputNumber>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div className={'mt-36 show-flex-box-r show-flex-sa btn-list'}>
                        <div
                            className={'show-flex-box-r show-flex-center f-22 color-white cursor-pointer cancel-btn'}
                            onClick={closeDialog}>Cancel
                        </div>
                        <div
                            className={'show-flex-box-r show-flex-center f-22 color-white cursor-pointer confirm-btn'}
                            onClick={() => clickConfirm(title, bitlifeList)}>{btnLabel}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}