import './confirmDialog.scss'
import React, { useEffect, useRef, useState } from "react";
import { getStr } from "src/utils/utils.ts";
interface modifyNumProps {
    title: string,
    totalBitLife: number,
    targetAddress: string,
    closeDialog: () => void,
    ensure: () => void,
}
export const ConfirmDialog:React.FC<modifyNumProps> = ({title, totalBitLife, targetAddress, closeDialog, ensure}) => {
    return (
        <div className={'show-flex-box-r show-flex-center confirm-dialog-wrap'}>
            <div className={'show-flex-box-c confirm-dialog-content'}>
                <div className={'c-00E6FF f-28 mt-30 ml-40'}>{title}</div>
                <div className={'cursor-pointer close-icon'} onClick={closeDialog}></div>
                <div className={'flex-1 mt-16 show-flex-box-c pl-50 pr-50 pt-20 content'}>
                    {
                        title.includes('Increase') && <div>
                            <span className={'color-white f-20'}>You are about to </span>
                            <span className={'c-0BF f-20'}> add {totalBitLife} </span>
                            <span className={'color-white f-20'}> BitLifes to the inventory of </span>
                            <span className={'c-0BF f-20'}> {getStr(targetAddress, 4, 4)} </span>
                        </div>
                    }
                    {
                        title.includes('Decrease') && <div>
                            <span className={'color-white f-20'}>You are about to </span>
                            <span className={'c-FC0 f-20'}> remove {totalBitLife} </span>
                            <span className={'color-white f-20'}> BitLifes from the inventory of </span>
                            <span className={'c-FC0 f-20'}> {getStr(targetAddress, 4, 4)} </span>
                        </div>
                    }

                    <div className={'show-flex-box-r show-flex-sa btn-list'}>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer cancel-btn'}
                            onClick={closeDialog}>Cancel
                        </div>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer confirm-btn'}
                            onClick={ensure}>Confirm
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}