import './userInfo.scss'
import editIcon from 'img/miner/edit-icon.png'
import {LinkDialog} from './linkDialog.tsx'
import {EditUserinfoDialog} from './editUserinfoDialog.tsx'
import {useEffect, useState, forwardRef } from "react";
import salesLinkIcon from 'img/miner/sales-link-icon.png'
import {useGlobalState} from "src/hook/useGlobalState.ts";
import {MinerApi} from "src/api";
import {useMessage} from "src/view/components/MessageContext.tsx";
interface userInfoProps {
    refresh: () => void,
}
export const UserInfo = forwardRef(({refresh}: userInfoProps) => {
    const [userInfo, setUserInfo] = useState({} as any)
    const [linkVisible, setLinkVisible] = useState(false as boolean)
    const [editUserinfoDialogVisible, setEditUserinfoDialog] = useState(false as boolean)
    const {address} = useGlobalState()
    const {showMessage, closeMessage} = useMessage()
    const getUserInfo = () => {
        const initUserInfo:any = localStorage.getItem('userInfo')
        const user = JSON.parse(initUserInfo)
        setUserInfo(user)
    }
    useEffect(() => {
        getUserInfo()
    }, []);
    const openLink = () => {
        setLinkVisible(true)
    }
    const closeLinkDialog = () => {
        setLinkVisible(false)
    }
    const openEditUserinfo = () => {
        setEditUserinfoDialog(true)
    }
    const closeEditUserinfoDialog = () => {
        setEditUserinfoDialog(false)
    }
    const saveUser = async (username: any) => {
        const bo = {
            ethAddress: address,
            nickName: username
        }
        try {
            const res:any = await MinerApi.modifyName(bo)
            if (res.code === 200) {
                showMessage('Modified Successfully')
                setEditUserinfoDialog(false)
                const storedData = localStorage.getItem('userInfo');
                if (storedData) {
                    const parsedData = JSON.parse(storedData);
                    parsedData.nickName = username;
                    localStorage.setItem('userInfo', JSON.stringify(parsedData));
                }
                refresh()
                getUserInfo()
            } else {
                showMessage(res.message)
            }
        } catch (error) { console.log(error) }
    }
    return (
        <div className={'show-flex-box-r show-flex-sb pl-60 pr-60 userinfo-wrap'}>
            <div className={'show-flex-box-r align-center left'}>
                <div className={'avatar-wrap'}>
                    <img className={'avatar-img'} src={userInfo.userAvatar} alt=""/>
                </div>
                <div className={'show-flex-box-c ml-40'}>
                    <div className={'show-flex-box-r align-center'}>
                        <div className={'color-white f-24'}>{userInfo.nickName}</div>
                        <img className={'ml-20 cursor-pointer edit-icon'} src={editIcon} alt="" onClick={openEditUserinfo}/>
                    </div>
                    <div className={'color-white f-24 mt-10'}>ID：{userInfo.viewCode}</div>
                </div>
            </div>
            <div className={'right'}>
                <div className={'show-flex-box-r show-flex-center cursor-pointer sales-link-btn'} onClick={openLink}>
                    <img className={'sales-link-icon'} src={salesLinkIcon} alt=""/>
                    <div className={'c-FC0 f-24 ml-20'}>My Sales Link</div>
                </div>
            </div>
            { linkVisible && <LinkDialog userInfo={userInfo} closeDialog={closeLinkDialog}></LinkDialog> }
            { editUserinfoDialogVisible && <EditUserinfoDialog userInfo={userInfo} closeDialog={closeEditUserinfoDialog} saveUser={saveUser}></EditUserinfoDialog> }
        </div>
    )
})