import { useRoutes, Navigate, useNavigate } from "react-router-dom";
import {Layout} from "../view/layout";
import { Home } from "src/view/home/home.tsx";
import { WelcomePage } from "src/view/welcome/Welcome.tsx";
// import { UserPage } from "src/view/user/User.tsx";
import React from "react";
import { NotFoundPage } from "src/view/components/NotFound.tsx";

import { Miner } from "src/view/miner/index.tsx";

const RedirectToHome = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        navigate('/welcome');
    }, [navigate]);

    return null;
};
const GetRouters = () => {
    return useRoutes([
        {
            path: "/",
            element: <Layout/>,
            children: [
                {
                    path: '/',
                    element: <RedirectToHome />,
                },
                {
                    path: '*',
                    element: <NotFoundPage />
                },
                {
                    path: 'home',
                    element: <Home/>
                },
                {
                    path: 'welcome',
                    element: <WelcomePage></WelcomePage>
                },
                // {
                //     path: 'user',
                //     element: <UserPage></UserPage>
                // },
                {
                    path: 'miner',
                    element: <Miner></Miner>
                },
            ]
        }
    ])
}
export default GetRouters