import "./Welcome.scss"
import { useNavigate, useSearchParams } from "react-router-dom";
import {useWeb3Modal} from '@web3modal/wagmi/react'
import {useGlobalState} from "src/hook/useGlobalState.ts";
import { useAccount, useAccountEffect, useSignMessage } from "wagmi";
import {useEffect, useRef, useState} from "react";
import {User} from "src/api";
import {useMessage} from "src/view/components/MessageContext.tsx";


export const WelcomePage = function () {
    const { signMessage, data, isSuccess, isError } =useSignMessage()
    const [messageTxt, setMessageTxt] = useState('')
    const navigate = useNavigate()
    const {address} = useGlobalState()
    const {open} = useWeb3Modal()
    const { setAddress } = useGlobalState()
    const { showMessage } = useMessage()
    const account = useAccount()

    const [accountAddress, setAccountAddress] = useState('')
    const loading = useRef(false)
    const getMessage = (address: string) => {
        if (!loading.current) {
            loading.current = true
            User.getMessage(address).then((res: any) => {
                if (res.code === 200) {
                    signMessage({message: res.data})
                    setMessageTxt(res.data)
                }
            })
        }
    }
    useEffect(() => {
        if (isError) {
            loading.current = false
        }
    }, [isError]);
    const loginAccount = () => {
        const formData = {
            ethAddress: accountAddress,
            hash: data,
            txt: messageTxt
        }
        User.login(formData).then((res: any) => {
            if (res.code === 200) {
                navigate('/miner')
                localStorage.setItem('minerToken', res.data.token)
                localStorage.setItem('userInfo', JSON.stringify(res.data))
                setAddress(accountAddress)
            } else if (res.code === 309) {
                showMessage(res.message)
                navigate('*')
            } else {
                showMessage('Login timeout')
            }
        })
    }
    useEffect(() => {
        if (isSuccess) {
            loading.current = false
            loginAccount()
        }
    }, [isSuccess]);

    const onConnect = () => {
        if (account.address !== undefined) {
            // setAccountAddress(account.address)
            getMessage(accountAddress)
        } else {
            open()
        }
    }

    useEffect(() => {
        if (account.address !== undefined) {
            setAccountAddress(account.address)
        }
    }, [account]);

    // useEffect(() => {
    //     const token = localStorage.getItem('minerToken')
    //     console.log(accountAddress, token)
    //     if (accountAddress && !token) {
    //         getMessage(accountAddress)
    //     }
    // }, [accountAddress]);

    return (
        <div className={'show-flex-box-c welcome-page'}>
            <div className={'title-wrap'}>
                <div className={'title'}>Welcome to the</div>
                <div className={'title'}>Cellula 4x4 BitLife Sales System</div>
            </div>
            {
                !address && <div className={'button-item'} onClick={() => onConnect()}>Wallet Connect</div>
            }
            {
                address && <div className={'button-item'} onClick={() => navigate('/miner')}>Get Started</div>
            }
        </div>
    )
}