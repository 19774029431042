import './miningMachine.scss'
import labelIcon from 'img/miner/label-icon.png'
import bitlifeImg from 'img/miner/bitlife.png'
import {handlePermillage} from "src/utils/utils.ts";
import {NoData} from 'src/view/components/noData.tsx'
import React, {useState, useEffect, useImperativeHandle, forwardRef, useRef} from "react";
import {MinerApi} from 'src/api'
import {useGlobalState} from "src/hook/useGlobalState.ts";
import {Loading} from "src/view/components/loading.tsx";

export const MiningMachine = forwardRef((props:any, ref:any) => {
    const {address} = useGlobalState()
    const tableTitle = ['4x4 BitLife', 'Remaining Quantity', 'Quantity Sold']
    const [inventoryList, setInventoryList] = useState([] as InventoryList[])
    const [inventoryAll, setInventoryAll] = useState({} as InventoryAll)
    const [isLoading, setIsLoading] = useState(true as boolean)
    // 获取矿机仓库
    const getInventoryList = async (address:string) => {
        try {
            setIsLoading(true)
            const res = await MinerApi.getGeneStock(address)
            setInventoryList(res.data.list)
            setIsLoading(false)
        } catch (error) {}
    }
    // 获取矿商总库存
    const getInventoryAll = async (address:string) => {
        try {
            const res = await MinerApi.getAllStock(address)
            setInventoryAll(res.data)
        } catch (error) {}
    }

    const getData = (address:string) => {
        getInventoryList(address)
        getInventoryAll(address)
    }

    useImperativeHandle(ref, () => ({
        callChildMethod: getData
    }));

    useEffect(() => {
        if (address) {
            getData(address)
        }
    }, [address]);
    return (
        <div className={'pt-22 pl-24 mining-machine-wrap'}>
            <div className={'color-white f-20 pt-16 pl-40 mining-machine-title'}>
                Inventory
            </div>
            <div className={'show-flex-box-r align-center mining-machine-info'}>
                <img className={'label-icon'} src={labelIcon} alt=""/>
                <div className={'c-FC0 f-16 ml-8'}>Remaining Stock：{handlePermillage(inventoryAll.availableStockNum)}</div>
                <img className={'ml-10 label-icon'} src={labelIcon} alt=""/>
                <div className={'c-0BF f-16 ml-8'}>Quantity Sold：{handlePermillage(inventoryAll.saleStockNum)}</div>
            </div>
            <div className={'show-flex-box-c mining-machine-list'}>
                <div className={'pl-30 mt-6 show-flex-box-r show-flex-sb table-title'}>
                    {
                        tableTitle.map((item:string, index:number) => (
                            <div className={'c-59D f-16 single-title'} key={index}>{item}</div>
                        ))
                    }
                </div>
                {
                    isLoading &&
                    <div className={'pr-20 table-content'}>
                        <Loading></Loading>
                    </div>
                }
                {
                    !isLoading &&
                    <div className={'mr-16 pb-10 table-content'}>
                        {
                            inventoryList.map((item: any, index: number) => (
                                <div className={'pl-46 mt-16 show-flex-box-r show-flex-sb single-bitlife'} key={index}>
                                    <div className={'bitlife-box'}>
                                        <div className={'bitlife-wrap'}>
                                            <div className={'cover'}
                                                 style={{backgroundImage: `url('${item.roleImage}')`}}>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={'color-white f-18 remaining-box'}>{handlePermillage(item.availableStockNum)}</div>
                                    <div
                                        className={'color-white f-18 soldout-box'}>{handlePermillage(item.saleStockNum)}</div>
                                </div>
                            ))
                        }
                        {inventoryList.length === 0 && <NoData></NoData>}
                    </div>
                }
            </div>
        </div>
    )
})