export const networkList = [
    {
        id: 97,
        chainId: '0x61',
        chainName: 'BNB Smart Chain Testnet',
        nativeCurrency: {
            name: 'tBNB',
            symbol: 'tBNB',
            decimals: 18,
        },
        rpcUrls: ['https://bsc-testnet-rpc.publicnode.com'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
        contract: '0x1E582C7d3cb604941383Edd3761cba853Be76852',
        api: 'https://factoryapi.cellula.fun/',
        faucet: 'https://mirror.xyz/cellulalifegame.eth/FBU5WKtTIB-5dRAw28e_NXmqp0fSq49A0za-DMWGQ9w',
        pacmanUrl: 'https://game.cellula.life/',
        websiteUrl: 'https://www.cellula.life/',
        url: 'https://play.cellula.life/',
        userDistributionUrl: 'https://sale.cellula.fun/',
        lifeContract: '0x3226260303bD8B817779C9dC0D731a8f6C3FeE5B',
        pointContract: '0x4D22397853E0bFe332EfDFCEc73B426bEA35291D',
        chargeContract: '0x9DA2Bd8D93495207De45C7edf001De41F5a7E3Cb',
        energyContract: '0xD04a650AD47088CC5392A994194013FC7A9308C7',
        recycleContract: '0x93bC0B69f1b60c38592c320Dc0E423Bd4Ce750Ff',
        batchCreateLife: '0x66F66be99D6EeE19b1E9943e9c44bf9040FA6DCD',
        claimContract: '0x24D38b76440A30f2443b31e199bEB5CAd03229Bf',
        winnerAddress: '0x7D1b34181A73D96DB8954305cCDA2997D4a32eB1',
        luckyAddress: '0xD1883fd26c2642A9dEc07a95780D00d194B2B849',
        airDrop: '0x0B949B176FD4709C364b86c3fb7E4b984131B25c',
        tokenContract: '0xAEa4EbC3FD88E99eB016c20cB0F10fdb7Fd709CB',
        stakingContract: '0xCfbBe1E034e3E43e130708863099DFa7632A6f53'
    },
    {
        id: 56,
        chainId: '0x38',
        chainName: 'BNB Smart Chain',
        nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18,
        },
        rpcUrls: ['https://rpc.ankr.com/bsc'],
        blockExplorerUrls: ['https://bscscan.com'],
        contract: '0xa258107cb9dcd325a37c7d65a7f4850bb9986bc6',
        api: 'https://factoryapi.cellula.life/',
        faucet: 'https://mirror.xyz/cellulalifegame.eth/FBU5WKtTIB-5dRAw28e_NXmqp0fSq49A0za-DMWGQ9w',
        pacmanUrl: 'https://game.cellula.life/',
        websiteUrl: 'https://www.cellula.life/',
        url: 'https://play.cellula.life/',
        userDistributionUrl: 'https://sale.cellula.life/',
        lifeContract: '0xabd1780208a62b9cbf9d3b7a1617918d42493933',
        pointContract: '0xE17EA225F1dBaEF85c8970D9C7863885a679E53e',
        chargeContract: '0x09d2b99B72C5261B85738149d2E35434A0957c44',
        recycleContract: '0xb0952610D1a7A46793f1595a04A4BF077962DD80',
        batchCreateLife: '0xE62871d9AEa78A2BeD31d985135aC454037b8B2c',
        claimContract: '0xDe1c2F586f22dB4C657CeCE13FE7e0Cf427d23E2',
        winnerAddress: '0xdF149cf787B2363f08d2ddC9A5141acE07492dfd',
        luckyAddress: '0xb2214A229e34EA750c310fc1CC99F38aea7A03A3',
        airDrop: '0x4306B223Ee8Edb91F0a38F958c611A2896a46149',
        tokenContract: '0xc790Ac3B67C42302555D928E5644B848C557E319',
        stakingContract: '0x8Ab64f1f53F691058243060A48E489fD8Ef641DA'
    },
]