import React, {useEffect, useRef, useState, useImperativeHandle, forwardRef} from "react";
import {handlePermillage} from "src/utils/utils.ts";
import './salesDetail.scss'
import arrowLeft from 'img/miner/arrow-left.png'
import arrowRight from 'img/miner/arrow-right.png'
import {NoData} from 'src/view/components/noData.tsx'
import { Popover,Tooltip  } from 'antd';
import {MinerApi} from 'src/api'
import {useGlobalState} from "src/hook/useGlobalState.ts";
import { canContinuePaging, formatDate, getStr } from "src/utils/utils.ts";
import {Loading} from "src/view/components/loading.tsx";
import * as XLSX from 'xlsx';
export const SalesDetail = forwardRef((props:any, ref:any) => {
    const [isLoading, setIsLoading] = useState(true as boolean)
    const {address} = useGlobalState()
    const [userInfo, setUserInfo] = useState({} as any)
    const [pageNumTop, setPageNumTop] = useState(1 as number)
    const [pageNumBottom, setPageNumBottom] = useState(1 as number)
    const [resellerListAll, setResellerListAll] = useState([] as any)
    const [resellerList, setResellerList] = useState([] as any)
    const [distributorIndex, setDistributorIndex] = useState(0 as number)

    const [nextTesellerListAll, setNextTesellerListAll] = useState([] as any)
    const [nextTesellerList, setNextTesellerList] = useState([] as any)
    const [distributorSubIndex, setDistributorSubIndex] = useState(0 as number)

    const [minerId, setMinerId] = useState('' as string)

    const [salesViewList, setSalesViewList] = useState({} as any)

    const orderTableTitle = [
        { label: 'Order Number', width: '10%', key: 'orderId' },
        { label: 'Price', width: '8%', key: 'buySinglePrice' },
        { label: 'Quantity', width: '7%', key: 'buyNum' },
        { label: 'Wallet Address', width: '25%', key: 'userEthAddress' },
        { label: 'Total Amount', width: '10%', key: 'orderPrice' },
        { label: 'BitAgent/Sub-BitAgent', width: '20%', key: 'distributionName' },
        { label: 'Order Status', width: '10%', key: 'orderStatus' },
        { label: 'BitLife Status', width: '10%', key: 'sendStatus' },
    ]
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize] = useState(30)
    const pageNum = useRef(1)
    const [orderList, setOrderList] = useState([] as any)
    const [bool, setBool] = useState<boolean>(false)

    const skipEffect = useRef(false);

    const changeDistributorTab = (index:number) => {
        setDistributorIndex(index)
        skipEffect.current = true;
        setPageNumBottom(1)
        setDistributorSubIndex(0)
    }

    const changeDistributorSubTab = (item:any, index:number) => {
        setDistributorSubIndex(index)
        setMinerId(item.id)
    }


    const getUser = () => {
        const initUserInfo:any = localStorage.getItem('userInfo')
        const user = JSON.parse(initUserInfo)
        setUserInfo(user)
    }
    // 获取全部分销商
    const getResellerList = async (address:string) => {
        try {
            const res:any = await MinerApi.getNextReseller(address, address, 1, 99)
            const data = res.data.list || []
            data.unshift({
                nickName: 'ALL',
                ethAddress: address,
                id: userInfo.minerId
            })
            setResellerListAll(data)
            setPageNumTop(1)
        } catch (error) {}
    }
    // 监听 resellerListAll 变化
    useEffect(() => {
        if (resellerListAll.length > 0) {
            renderReseller(pageNumTop)
        }
    }, [resellerListAll, pageNumTop]);
    const renderReseller = (page:number) => {
        const data = resellerListAll.slice((page - 1) * 10, page * 10)
        setResellerList(data)
    }
    const updatePageNumTop = (index:number) => {
        const page = pageNumTop + index
        setDistributorIndex(0)
        setPageNumTop(page)
        skipEffect.current = true
        setPageNumBottom(1)
        setDistributorSubIndex(0)
    }
    // 监听 resellerList 变化
    useEffect(() => {
        if (resellerList.length > 0) {
            if (resellerList[distributorIndex]?.nickName === 'ALL') {
                getAllSub()
            } else {
                getNextList(distributorIndex)
            }
            setDistributorSubIndex(0)
        }
    }, [distributorIndex, resellerList]);

    // 获取全部的子级分销商
    const getAllSub = async () => {
        try {
            const res:any = await MinerApi.getAllSubReseller(address, 1, 99)
            const data = res.data.list || []
            data.unshift({
                nickName: 'ALL',
                ethAddress: address,
                id: userInfo.minerId
            })
            setNextTesellerListAll(data)
        } catch (error) {}
    }

    // 矿商获取子级分销商
    const getNextList = async (index:number) => {
        try {
            const targetAddress = resellerList[index].ethAddress
            const res:any = await MinerApi.getNextReseller(address, targetAddress, pageNumBottom, 99)
            const data = res.data.list || []
            console.log(resellerList[distributorIndex])
            data.unshift({
                nickName: resellerList[distributorIndex]?.nickName,
                ethAddress: address,
                id: resellerList[index]?.id + '_mine'
            })
            data.unshift({
                nickName: 'ALL',
                ethAddress: address,
                id: resellerList[index]?.id
            })
            setNextTesellerListAll(data)
        } catch (error) {}
    }

    // 监听 nextTesellerListAll 变化
    useEffect(() => {
        if (nextTesellerListAll.length > 0) {
            renderNextReseller(pageNumBottom)
            if (userInfo.type === 0) {
                setMinerId(resellerList[distributorIndex].id)
            } else {
                setMinerId(userInfo.minerId)
            }
        }
    }, [nextTesellerListAll]);

    useEffect(() => {
        if (skipEffect.current) {
            skipEffect.current = false;  // Reset skip flag after skipping
            return; // 跳过执行
        }
        const data = nextTesellerListAll.slice((pageNumBottom - 1) * 10, pageNumBottom * 10)
        setNextTesellerList(data)
        if (data.length > 0) {
            setMinerId(data[0].id)
        }
    }, [pageNumBottom]);
    const renderNextReseller = (page:number) => {
        const data = nextTesellerListAll.slice((page - 1) * 10, page * 10)
        setNextTesellerList(data)
    }
    const updatePageNumBottom = (index:number) => {
        const page = pageNumBottom + index
        skipEffect.current = false
        setPageNumBottom(page)
        setDistributorSubIndex(0)
    }

    // 监听 minerId 变化
    useEffect(() => {
        if (minerId) {
            let bool = false
            if (nextTesellerList[distributorSubIndex]?.nickName === 'ALL') {
                bool = true
            }
            setBool(bool)
            let initMinerId = JSON.parse(JSON.stringify(minerId))
            if (minerId && typeof minerId === 'string' && minerId.split('_').length > 0) {
                initMinerId = minerId.split('_')[0]
            }
            getSaleTotal(initMinerId, bool)
            getSaleDetail(initMinerId, bool)
        }
    }, [minerId]);

    const getSaleTotal = async (minerId: string, bool:boolean) => {
        try {
            const res = await MinerApi.getSaleTotalInfo(minerId, bool)
            setSalesViewList(res.data)
        } catch (error) {}
    }

    const pageLoading = useRef(false)
    const handlePage = () => {
        if (!dataContainerRef.current || pageLoading.current || !canContinuePaging(pageNum.current, pageSize, total)) return;
        const { scrollTop, scrollHeight, clientHeight } = dataContainerRef.current;

        // 是否滚动到底部
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 300;
        if (isAtBottom) {
            pageLoading.current = true
            pageNum.current++
            MinerApi.getSaleDetailInfo(minerId, bool, pageNum.current, pageSize).then((res: any) => {
                const arr = res.data.list.map((item:any) => {
                    return {
                        ...item,
                        distributionName: item.secondDistributionName ? item.distributionName + '/' + item.secondDistributionName : item.distributionName,
                        sendStatus: item['sendStatus'] === 0 ? 'Pending Disrtribution' : item['sendStatus'] === 1 ? 'Distributed' : '——',
                        orderStatus: item['orderStatus'] === 0 ? 'Pending Confirmation' : item['orderStatus'] === 1 ? 'Confirmed' : 'Expired'
                    }
                })
                setOrderList((prevItems:any) => [
                    ...prevItems,
                    ...arr
                ])
                setTotal(res.data.total)
                pageLoading.current = false
            })
        }
    }
    const dataContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (dataContainerRef.current && orderList.length) {
            dataContainerRef.current.addEventListener('scroll', handlePage);
        }
    }, [orderList.length]);

    const getSaleDetail = async (minerId: string, bool:boolean) => {
        try {
            setIsLoading(true)
            const res = await MinerApi.getSaleDetailInfo(minerId, bool, pageNum.current, pageSize)
            let list = res.data.list || []
            if (res.data.list.length > 0) {
                list = res.data.list.map((item:any) => {
                    return {
                        ...item,
                        distributionName: item.secondDistributionName ? item.distributionName + '/' + item.secondDistributionName : item.distributionName,
                        sendStatus: item['sendStatus'] === 0 ? 'Pending Disrtribution' : item['sendStatus'] === 1 ? 'Distributed' : '——',
                        orderStatus: item['orderStatus'] === 0 ? 'Pending Confirmation' : item['orderStatus'] === 1 ? 'Confirmed' : 'Expired'
                    }
                })
            }
            setTotal(res.data.total)
            setOrderList(list)
            setIsLoading(false)
        } catch (error) {}
    }

    // 分销商获取子级分销商
    const getNextListF = async () => {
        try {
            const res:any = await MinerApi.getNextReseller(address, address, 1, 99)
            const data = res.data.list || []
            data.unshift({
                nickName: 'Mine',
                ethAddress: address,
                id: userInfo.minerId + '_mine'
            })
            data.unshift({
                nickName: 'ALL',
                ethAddress: address,
                id: userInfo.minerId
            })
            setPageNumTop(1)
            setNextTesellerListAll(data)
        } catch (error) {}
    }

    useImperativeHandle(ref, () => ({
        callChildMethod: getData
    }));

    const getData = (address:string) => {
        setPageNumTop(1)
        setPageNumBottom(1)
        setDistributorIndex(0)
        setDistributorSubIndex(0)
        setMinerId('')
        if (userInfo.type === 0) {
            getResellerList(address)
        } else if (userInfo.type === 1) {
            getNextListF()
        } else {
            setMinerId(userInfo.minerId)
        }
    }
    useEffect(() => {
        if (address) {
            getData(address)
        }
    }, [address, userInfo]);
    useEffect(() => {
        getUser()
    }, []);
    const popoverStyle = {
        backgroundColor: 'black',  // 设置背景色为黑色
        color: 'white',            // 设置文字颜色为白色，使其可读
        borderRadius: '8px',       // 可选：设置边角圆滑
        padding: '10px',           // 可选：设置内边距
    };
    const download = async () => {
        let bool = false
        if (nextTesellerList[distributorSubIndex]?.nickName === 'ALL') {
            bool = true
        }
        const res = await MinerApi.exportDetail(minerId, bool)
        if (res.data.length > 0) {
            const data = res.data.map((item:any) => {
                return {
                    ...item,
                    sendStatus: item['sendStatus'] === 0 ? 'Pending Disrtribution' : item['sendStatus'] === 1 ? 'Distributed' : '——',
                    orderStatus: item['orderStatus'] === 0 ? 'Pending Confirmation' : item['orderStatus'] === 1 ? 'Confirmed' : 'Expired'
                }
            })
            const ws = XLSX.utils.json_to_sheet(data);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'salesOrders.xlsx');
        }
    }
    return (
        <div className={'show-flex-box-c sales-detail-wrap'}>
            <div className={'color-white f-20 pt-19 pl-40 fs-0 sales-detail-title'}>
                Sales Details
            </div>
            <div className={'show-flex-box-r show-flex-center cursor-pointer c-00E6FF f-18 download-btn'} onClick={download}>
                Download
            </div>
            <div className={'fs-0 tab-list'}>
                {
                    userInfo.type === 0 &&
                    <div className={'pr-30 mt-10 show-flex-box-r align-center distributor-tab'}>
                        <div className={'c-0BF f-18 mr-10 label'}>BitAgent：</div>
                        <div className={`cursor-pointer fs-0 mr-10 arrow-left ${pageNumTop === 1 ? 'btn-disabled' : ''}`} onClick={() => updatePageNumTop(-1)}></div>
                        <div className={`show-flex-box-r tab-list`}>
                            {
                                resellerList.map((item: any, index: number) => (
                                    <Tooltip placement="top" title={item.nickName} key={index}>
                                        <div
                                            className={`c-0BF cursor-pointer f-18 single-distributor ${index === distributorIndex ? 'single-distributor-active' : ''}`}
                                            onClick={() => changeDistributorTab(index)}>{item.nickName}</div>
                                    </Tooltip>
                                ))
                            }
                        </div>
                        <div className={`cursor-pointer fs-0 ml-10 arrow-right ${resellerListAll.length === 0 || Math.ceil(resellerListAll.length / 10) <= pageNumTop ? 'btn-disabled' : ''}`} onClick={() => updatePageNumTop(1)}></div>
                    </div>
                }
                {
                    (userInfo.type === 0 || userInfo.type === 1) &&
                    <div className={'pr-30 mt-10 show-flex-box-r align-center distributor-sub-tab'}>
                        <div className={'c-0BF f-18 mr-10 label'}>Sub-BitAgent：</div>
                        <div
                            className={`cursor-pointer fs-0 mr-10 arrow-left ${pageNumBottom === 1 ? 'btn-disabled' : ''}`} onClick={() => updatePageNumBottom(-1)}></div>
                        <div className={`show-flex-box-r tab-list`}>
                            {
                                nextTesellerList.map((item: any, index: number) => (
                                    <Tooltip placement="top" title={item.nickName} key={index} color={'#000'}>
                                        <div
                                            className={`c-0BF cursor-pointer f-18 single-distributor ${index === distributorSubIndex ? 'single-distributor-active' : ''}`}
                                            onClick={() => changeDistributorSubTab(item, index)}>{item.nickName}</div>
                                    </Tooltip>
                                    // <Popover content={item.nickName} key={index}>
                                    //     <div
                                    //         className={`c-0BF cursor-pointer f-18 single-distributor ${index === distributorSubIndex ? 'single-distributor-active' : ''}`}
                                    //         onClick={() => changeDistributorSubTab(item, index)}>{item.nickName}</div>
                                    // </Popover>
                                ))
                            }
                        </div>
                        <img className={`cursor-pointer fs-0 ml-10 arrow-right ${ nextTesellerList.length === 0 || Math.ceil(nextTesellerListAll.length / 10) <= pageNumBottom ? 'btn-disabled' : ''}`} src={arrowRight} alt="" onClick={() => updatePageNumBottom(1)}/>
                    </div>
                }
            </div>

            <div className={'mt-20 fs-0 show-flex-box-r show-flex-sa sales-view'}>
                <div className={'show-flex-box-c'}>
                    <div className={'c-59D f-20'}>Remaining Stock：</div>
                    <div className={'color-white f-24'}>{salesViewList?.restStockNum || '——'}</div>
                </div>
                <div className={'show-flex-box-c'}>
                    <div className={'c-59D f-20'}>Total Sales Quantity：</div>
                    <div className={'color-white f-24'}>{salesViewList?.totalSaleNum || '——'}</div>
                </div>
                <div className={'show-flex-box-c'}>
                    <div className={'c-59D f-20'}>Total Orders：</div>
                    <div className={'color-white f-24'}>{salesViewList?.totalOrderNum || '——'}</div>
                </div>
                <div className={'show-flex-box-c'}>
                    <div className={'c-59D f-20'}>Total Sales Amount：</div>
                    <div className={'color-white f-24'}>{salesViewList?.totalOrderAmt || '——'}</div>
                </div>
                {/*{*/}
                {/*    salesViewList.map((item: any, index: number) => (*/}
                {/*        <div key={index} className={'show-flex-box-c'}>*/}
                {/*            <div className={'c-59D f-20'}>{item.label}</div>*/}
                {/*            <div className={'c-0BF f-24'}>{handlePermillage(item.value)}</div>*/}
                {/*        </div>*/}
                {/*    ))*/}
                {/*}*/}
            </div>

            {
                isLoading &&
                <div className={'pr-20 flex-1 order-list'}>
                    <Loading></Loading>
                </div>
            }
            {
                !isLoading &&
                <div className={'order-list flex-1 show-flex-box-c'}>
                    <div className={'show-flex-box-r pl-30 align-center fs-0 table-title'}>
                        {
                            orderTableTitle.map((item: any, index: number) => (
                                <div className={`c-59D f-16 ${[1, 2, 4].includes(index) ? 'text-center' : ''}`} key={index} style={{width: item.width}}>{item.label}</div>
                            ))
                        }
                    </div>
                    <div className={'flex-1 show-flex-box-c table-content'} ref={dataContainerRef}>
                        {orderList.map((item: any, index: number) => (
                            <div key={index} className={`show-flex-box-r align-center pl-30 mb-12 fs-0 single-order`}>
                                {orderTableTitle.map((title, i) => (
                                    <div key={i} className={`color-white f-16 pr-10 ${[1, 2, 4].includes(i) ? 'text-center' : ''}`} style={{width: title.width}}>
                                        {item[title.key]}
                                    </div>
                                ))}
                            </div>
                        ))}
                        {orderList.length === 0 && <NoData></NoData>}
                    </div>
                </div>
            }
        </div>
    )
})