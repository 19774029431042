import './recycleDialog.scss'
import React, { useEffect, useRef, useState } from "react";
interface recycleDialogProps {
    totalNUm: number,
    name: string,
    closeDialog: () => void,
    ensure: () => void,
}
export const RecycleDialog:React.FC<recycleDialogProps> = ({totalNUm, name, closeDialog, ensure}) => {
    return (
        <div className={'show-flex-box-r show-flex-center confirm-dialog-wrap'}>
            <div className={'show-flex-box-c confirm-dialog-content'}>
                <div className={'c-00E6FF f-28 mt-30 ml-40'}>recycle</div>
                <div className={'cursor-pointer close-icon'} onClick={closeDialog}></div>
                <div className={'flex-1 mt-16 show-flex-box-c pl-50 pr-50 pt-20 content'}>
                    <div>
                        <span className={'color-white f-20'}>You are about to&nbsp;</span>
                        <span className={'c-FC0 f-20'}> recover {totalNUm}&nbsp;</span>
                        <span className={'color-white f-20'}>BitLifes from&nbsp;</span>
                        <span className={'c-FC0 f-20'}>{name}</span>
                    </div>

                    <div className={'show-flex-box-r show-flex-sa btn-list'}>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer cancel-btn'}
                            onClick={closeDialog}>Cancel
                        </div>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer confirm-btn'}
                            onClick={ensure}>Confirm
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}