import './createBitAgent.scss'
import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import {useMessage} from "src/view/components/MessageContext.tsx";
interface createBitAgentProps {
    title: string,
    btnLabel: string,
    closeDialog: () => void,
    clickNext: (address:any) => void,
}
export const CreateBitAgent:React.FC<createBitAgentProps> = ({title, btnLabel, closeDialog, clickNext}) => {
    const [addressValue, setAddressValue] = useState('')
    const {showMessage, closeMessage} = useMessage()
    const changeAddressValue = (value: any) => {
        setAddressValue(value.target.value)
    }
    const clickConfirm = () => {
        // 正则表达式：以0x开头，只包含字母和数字（不包括中文、空格和符号）
        const evmPattern = /^0x[a-fA-F0-9]{40}$/;
        // 测试地址是否符合正则表达式
        const isTrueEvmAddress = evmPattern.test(addressValue)
        if (!isTrueEvmAddress) {
            showMessage('Invalid Address Format')
            return
        }
        if (btnLabel === 'Next') {
            clickNext(addressValue)
        }
    }
    return (
        <div className={'show-flex-box-r show-flex-center create-bit-agent-wrap'}>
            <div className={'show-flex-box-c create-bit-agent-content'}>
                <div className={'c-00E6FF f-28 mt-30 ml-40'}>{title}</div>
                <div className={'cursor-pointer close-icon'} onClick={closeDialog}></div>
                <div className={'flex-1 mt-16 show-flex-box-c pl-50 pr-50 pt-20 content'}>
                    <div className={'color-white f-20 ff-Inter'}>Wallet Address（0X....asda）</div>
                    <input className={'mt-24 address-input'} value={addressValue} onChange={(e) => changeAddressValue(e)} />
                    <div className={'show-flex-box-r align-center mt-24'}>
                        <div className={'f-18 c-FF4747 mt-6'}>*</div>
                        <div className={'f-18 c-FF4747 ml-4'}>You can create up to 99 BitAgents</div>
                    </div>
                    <div className={'mt-36 show-flex-box-r show-flex-sa btn-list'}>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer cancel-btn'} onClick={closeDialog}>Cancel
                        </div>
                        <div
                            className={'show-flex-box-r show-flex-center f-18 color-white cursor-pointer confirm-btn'} onClick={clickConfirm}>{btnLabel}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}